<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.room_status')}} {{$t('globalTrans.search')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization"  vid="org_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Guest House Name"  vid="guest_house_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="guest_house_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.guest_house_name')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.guest_house_id"
                                  :options="guestHouseList"
                                  id="guest_house_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Room Type"  vid="room_type_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="room_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.room_type')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.room_type_id"
                                  :options="roomTypeList"
                                  id="room_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Room No"  vid="room_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="room_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.room_no')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.room_id"
                                  :options="roomlist"
                                  id="room_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Start Date"  vid="start_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="start_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.start_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="search.start_date"
                                  id="start_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="End Date"  vid="end_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="end_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.end_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="search.end_date"
                                  id="end_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Gender"  vid="gender_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="gender_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.gender')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.gender_id"
                                  :options="genderList"
                                  id="gender_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('elearning_iabm.room_status')}}</h4>
              </template>
              <template v-slot:headerAction1>

              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <slot v-if='datas.length'>
                      <div class="col-3 mb-3 box" v-for="(list, index) in datas" :key="index">
                        <div class="card" :class="list.booking.length ? 'bg-warning': 'bg-success'">
                          <div class="card-body">
                            <h4 class="text-center text-white">{{$t('elearning_config.room_no')}}: {{ $n(list.room_no) }} </h4>
                            <h5 class="text-center text-white">{{$t('elearning_config.floor_no')}}:  {{ $n(list.floor_no )}} </h5>
                          </div>
                        </div>
                        <div class="box-content">
                            <div class="inner-content">
                              <b-table-simple hover small caption-top v-if="list.booking.length > 0">
                                <b-tr>
                                  <b-th class="text-white">{{$t('globalTrans.start_date')}}</b-th>
                                  <b-th class="text-white">{{$t('globalTrans.end_date')}}</b-th>
                                </b-tr>
                                <b-tr v-for="(booking, index1) in list.booking" :key="index + index1">
                                  <b-td> {{booking.start_date  | dateFormat }} </b-td>
                                  <b-td> {{booking.end_date  | dateFormat }} </b-td>
                                </b-tr>
                              </b-table-simple>
                            </div>
                        </div>
                      </div>
                    </slot>
                    <div class="panel-body text-center mt-3 col-12" v-else>
                      <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                    </div>
                  </b-row>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
    </b-container>
</template>
<style scoped>
.box:before,
.box:after,
.box .box-content:before,
.box .box-content:after{
    content: "";
    background: #fff;
    width: 50%;
    height: 4px;
    transform: scaleX(0);
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
    transition: all 600ms ease;
}
.box:after{
    top: auto;
    bottom: 15px;
    left: auto;
    right: 15px;
}
.box:hover:before,
.box:hover:after,
.box:hover .box-content:before,
.box:hover .box-content:after{
    transform: scale(1);
}
.box .inner-content{
    color: #fff;
    text-align: center;
    width: 90%;
    opacity: 0;
    position: absolute;
    top: 1%;
    background: chocolate;
}
.box:hover .inner-content{
    opacity: 1;
    z-index: 99;
}
.box .title{
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 3px;
}
.box .post{
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0 0 12px;
    display: block;
}
.box .icon li a:hover{
    color: #b31217;
    background: #fff;
    border-radius: 0 10px 0 10px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { bookingBookingCalender, circularPublicationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
              org_id: 0,
              guest_house_id: 0,
              gender_id: 0,
              room_type_id: 0,
              room_id: 0,
              fiscal_year_id: 0
            },
            guestHouseList: [],
            roomlist: [],
            roomTypeList: [],
            datas: []
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      genderList: function () {
        return this.$store.state.TrainingElearning.commonObj.genderList
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return this.$t('elearning_iabm.booking') + ' ' + this.$t('globalTrans.view')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('globalTrans.name'), class: 'text-left' },
              { label: this.$t('globalTrans.mobile'), class: 'text-left' },
              { label: this.$t('elearning_config.room_type'), class: 'text-left' },
              { label: this.$t('elearning_config.floor_no'), class: 'text-left' },
              { label: this.$t('elearning_config.room_no'), class: 'text-left' },
              { label: this.$t('globalTrans.start_date'), class: 'text-left' },
              { label: this.$t('globalTrans.end_date'), class: 'text-left' },
              { label: this.$t('elearning_config.room_rent'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'name_bn' },
              { key: 'mobile' },
              { key: 'room_type_bn' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'name' },
              { key: 'mobile' },
              { key: 'room_type' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      // this.loadData()
    },
    watch: {
      'search.org_id': function (newValue) {
        this.guestHouseList = this.getGuestHouseList(newValue)
      },
      'search.guest_house_id': function (newValue) {
        this.roomTypeList = this.getRoomTypeList(newValue)
      },
      'search.room_type_id': function (newValue) {
        this.roomlist = this.getRoomList(newValue)
      }
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getGuestHouseList (orgId) {
        return this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1 && item.org_id === orgId)
      },
      getRoomTypeList (guestHouseId) {
        return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1 && item.guest_house_id === guestHouseId)
      },
      getRoomList (typeId) {
        const roomlist = this.$store.state.TrainingElearning.commonObj.roomList.filter(item => item.status === 1 && item.room_type_id === typeId)
        return roomlist
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, circularPublicationToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, bookingBookingCalender, params).then(response => {
          if (response.success) {
            this.datas = response.data
          } else {
            this.datas = []
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this)
      }
    }
}
</script>
